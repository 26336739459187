import React, { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import BinIcon from '@ancon/wildcat-ui/shared/icons/trash-bin-outline.svg'
import { useRouter } from 'next/router'
import { OrderFormat } from '@ancon/wildcat-types'
import noop from 'lodash/noop'

import {
  checkoutCurrentCheckoutDetailsSelector,
  checkoutCurrentCheckoutPendingSelector,
  checkoutCurrentCheckoutPenguinLockersMetaDataSelector,
  checkoutDeletePendingSelector,
  checkoutItemsSelector,
  generalCheckoutTotalItemQtySelector,
} from '../../store/checkoutSelectors'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import MinimumOrderModal from '../MinimumOrderModal'
import {
  checkoutSetIsVisibleBoxPickupScheduleOrderModal,
  checkoutSetIsVisibleDeleteOrderModal,
  checkoutSetIsVisibleMinimumOrderModal,
} from '../../store/checkoutSlice'
import { AppRoutes } from '../../../app/constants'
import Button from '../../../app/components/Button'
import OrderTotal from '../orderTotal/OrderTotal'
import { updateCheckout } from '../../store/checkoutThunks'
import BoxPickupScheduleOrderModal from '../boxPickupScheduleOrderModal/BoxPickupScheduleOrderModal'
import useAppStore from '../../../../store/hooks/useAppStore'
import useGetBoxPickupServiceTime from '../../hooks/useGetBoxPickupServiceTime'

import CartItemList from './CartItemList'
import CartFooter from './CartFooter'
import CartHeader from './CartHeader'
import CartOutOfStockPopup from './CartOutOfStockPopup'
import styles from './ShoppingCart.module.scss'

export default function ShoppingCart() {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()
  const store = useAppStore()
  const router = useRouter()

  const checkout = useAppSelector(checkoutCurrentCheckoutDetailsSelector)
  const checkoutItems = useAppSelector(checkoutItemsSelector)
  const checkoutTotalItemsQty = useAppSelector(
    generalCheckoutTotalItemQtySelector,
  )
  const checkoutPending = useAppSelector(checkoutCurrentCheckoutPendingSelector)
  const checkoutDeletePending = useAppSelector(checkoutDeletePendingSelector)

  const [checkingStockErrors, setCheckingStockErrors] = useState<boolean>(false)

  const { outlet, minimumOrderDifferenceAmount, orderFormat, serviceTime } =
    checkout!

  const isBoxPickup = orderFormat === OrderFormat.BoxPickup

  const getBoxPickupServiceTime = useGetBoxPickupServiceTime()

  function getCheckoutServiceTime() {
    return isBoxPickup ? getBoxPickupServiceTime(serviceTime) : serviceTime
  }

  async function handleGoToCheckout() {
    const penguinLockersMetaData =
      checkoutCurrentCheckoutPenguinLockersMetaDataSelector(store.getState())

    const isOpenBoxPickupScheduleOrderModal =
      isBoxPickup &&
      (!penguinLockersMetaData?.timeBegin ||
        !penguinLockersMetaData?.expireTime)

    if (isOpenBoxPickupScheduleOrderModal) {
      dispatch(checkoutSetIsVisibleBoxPickupScheduleOrderModal(true))
    } else if (
      minimumOrderDifferenceAmount &&
      minimumOrderDifferenceAmount?.amount > 0
    ) {
      dispatch(checkoutSetIsVisibleMinimumOrderModal(true))
    } else {
      setCheckingStockErrors(true)
      await dispatch(updateCheckout({}))
        .unwrap()
        .then(() => {
          router.push({ pathname: AppRoutes.Checkout })
        })
        .catch(noop)
        .finally(() => {
          setCheckingStockErrors(false)
        })
    }
  }

  function handleDeleteOrder() {
    dispatch(checkoutSetIsVisibleDeleteOrderModal(true))
  }

  function handleContinueOutOfStockPopup() {
    router.push({ pathname: AppRoutes.Checkout })
  }

  const footerButtonDisabled = checkoutPending || checkoutDeletePending

  return (
    <div className={styles.container}>
      <CartHeader
        title={t('components.order.myOrder')}
        outletName={outlet.name}
        orderFormat={orderFormat}
        serviceTime={getCheckoutServiceTime()}
        loading={checkoutPending}
      />
      <CartItemList
        items={checkoutItems}
        canEditItems
        itemDisabled={checkoutPending}
      />
      <CartFooter
        totalItemsQty={checkoutTotalItemsQty}
        cartTotal={<OrderTotal orderSummaryTotals={checkout!} />}
        footerButtons={[
          <Button
            disabled={footerButtonDisabled}
            loading={checkingStockErrors}
            onClick={handleGoToCheckout}
          >
            {t('components.order.goToCheckout')}
          </Button>,
          <Button
            variant="secondary"
            disabled={footerButtonDisabled}
            onClick={handleDeleteOrder}
          >
            <BinIcon />
            {t('deleteOrderModal.title')}
          </Button>,
        ]}
      />
      <CartOutOfStockPopup onContinue={handleContinueOutOfStockPopup} />
      {minimumOrderDifferenceAmount && <MinimumOrderModal />}
      <BoxPickupScheduleOrderModal onClickContinue={handleGoToCheckout} />
    </div>
  )
}
