import { Suspense, lazy } from 'react'
import useTranslation from 'next-translate/useTranslation'

import Button from '../../../../app/components/Button'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { showAuthModal } from '../../../../auth/store/authSlice'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import {
  authCustomerIsLoggedInSelector,
  authInitializedSelector,
} from '../../../../auth/store/authSelectors'
import ClientAuthContainer from '../../../../auth/components/ClientAuthContainer'
import Skeleton from '../../../../app/components/Skeleton'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../../../app/constants'
import FillYourInfoModal from '../../../../auth/components/FillYourInfoModal'
import { AuthModalType } from '../../../../auth/types'
import useDeviceSize from '../../../../app/hooks/useDeviceSize'
import AuthSignInURLFilter from '../../../../auth/components/AuthSignInURLFilter'
import { preOrderUserSelector } from '../../../../preOrder/store/preOrderSelectors'
import AuthSignUpURLFilter from '../../../../auth/components/AuthSignUpURLFilter'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'

import NavbarUserProfile from './NavbarUserProfile'
import NavLanguageSwitcher from './NavLanguageSwitcher'
import CartButton from './CartButton'
import styles from './NavActions.module.scss'

const AuthModal = lazy(() => import('../../../../auth/components/authModal'))
const AuthProfileSelectionModal = lazy(
  () =>
    import(
      '../../../../auth/components/profileSelection/AuthProfileSelectionModal'
    ),
)
const CompanyMemberVerificationPendingModal = lazy(
  () =>
    import(
      '../../../../company/components/CompanyMemberVerificationPendingModal'
    ),
)
const CompanySignUpModal = lazy(
  () => import('../../../../auth/components/companySignUp/CompanySignUpModal'),
)
const ReviewAccountDetailsModal = lazy(
  () =>
    import(
      '../../../../auth/components/reviewAccount/ReviewAccountDetailsModal'
    ),
)

export default function NavActions() {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const isWhiteLabeled = isAppWhiteLabeled()

  const isLoggedIn = useAppSelector(authCustomerIsLoggedInSelector)
  const isAuthInitialized = useAppSelector(authInitializedSelector)
  const shouldHideCartButton = useRouteMatch([
    AppRoutes.Checkout,
    AppRoutes.PreOrderLink,
    AppRoutes.GroupOrder,
    AppRoutes.QROrderStatus,
  ])
  const { isMobile } = useDeviceSize()
  const preOrderUser = useAppSelector(preOrderUserSelector)

  const isNonOrderRoute = useRouteMatch([AppRoutes.Auth, AppRoutes.GiftCard])
  const isPreOrderStartRoute = useRouteMatch([
    AppRoutes.PreOrderLink,
    AppRoutes.GroupOrder,
  ])

  const isShowProfile = !!isLoggedIn || preOrderUser
  // Prevent showing sign-in button when isLoggedIn = null
  const isShowSignIn =
    isLoggedIn === false && !preOrderUser && !isPreOrderStartRoute

  function handleSignInClick() {
    dispatch(showAuthModal(AuthModalType.SignIn))
  }

  return (
    <>
      {!isNonOrderRoute && (
        <div className={styles.actionButtonContainer}>
          {!shouldHideCartButton && (
            <ClientAuthContainer
              skeleton={<Skeleton className={styles.cartBtnSkeleton} />}
            >
              <CartButton />
            </ClientAuthContainer>
          )}
          {!isMobile && (
            <>
              {isShowProfile && <NavbarUserProfile />}
              {isShowSignIn && (
                <Button
                  size="large"
                  variant="tertiary"
                  onClick={handleSignInClick}
                  disabled={!isAuthInitialized}
                  className={styles.signInButton}
                >
                  {t('components.navBar.signIn')}
                </Button>
              )}
            </>
          )}
          <ClientAuthContainer fallbackSpinning={false}>
            <Suspense>
              <AuthModal />
              <FillYourInfoModal />
              <AuthProfileSelectionModal />
              <CompanyMemberVerificationPendingModal />
              <AuthSignInURLFilter />
              <AuthSignUpURLFilter />
              {isWhiteLabeled && (
                <>
                  <CompanySignUpModal />
                  <ReviewAccountDetailsModal />
                </>
              )}
            </Suspense>
          </ClientAuthContainer>
        </div>
      )}
      <NavLanguageSwitcher />
    </>
  )
}
