import {
  CheckoutDetails,
  OrderFormat,
  SalesLocationType,
  CheckoutSalesLocation,
  OutletListItem,
  ServiceTime,
  CheckoutDelivery,
  CheckoutSummary,
  PaymentProviderResult,
  CheckoutStatus,
  TicketStatus,
  TicketItemStatus,
  PreOrderStatus,
  AttendeePreOrderStatus,
  BoxPickUpStation,
  PreOrderCancelInfo,
  Amount,
  CheckoutDiscount,
  CheckoutMetaDataItem,
  CheckoutDeliveryStatus,
  CheckoutDeliveryProvider,
  IDNameItem,
} from '@ancon/wildcat-types'
import { CheckoutRequestItem } from '@ancon/wildcat-utils/checkout/types'
import { CheckOrderSummaryItem } from '@ancon/wildcat-utils/order/types'

export type CheckoutFilters = {
  orderFormat: OrderFormat | null
  serviceTime: ServiceTime
  salesLocation: CheckoutSalesLocation | null
  delivery: CheckoutDelivery | null
  section: BoxPickUpStation | null
}

export type CheckoutOutOfStockAdditionalData = {
  stockProductId: string
  productId: string
  variantId: string
  count: number
  reorderCount: number
}

export enum CheckoutTipModalType {
  None,
  Info,
  AddTip,
}

export type CheckoutTipState = {
  tipPercentage: number | null
  customTipAmount: number | null
  visibleModalType: CheckoutTipModalType
  isSkipTipModal: boolean | null
}

export type CheckoutReducerState = {
  checkoutFilters: CheckoutFilters

  currentCheckoutOutletId: string | null
  currentCheckoutOutletListDetails?: OutletListItem | null

  currentCheckoutId?: string | null
  currentCheckoutDetails: CheckoutDetails | null
  currentCheckoutSelectedCheckoutItemId?: string | null
  currentCheckoutDetailsFetchPending: boolean
  currentCheckoutPaymentPending: boolean
  checkoutOutletListItemFetchPending: boolean

  checkoutSummaryFetchPending: boolean
  checkoutSummary: CheckoutSummary | null
  checkoutSummaryOutletListItem: OutletListItem | null

  checkoutWayOfPaymentsFetchPending: boolean
  adyenPaymentMethodsFetchPending: boolean
  hasAdyenPaymentMethods: boolean
  activeAdyenIntegrationFetchPending: boolean

  checkoutAddItemPending: boolean
  checkoutUpdatePending: boolean
  checkoutApplyDiscountPending: boolean
  checkoutUpdateItemQuantityPending: boolean
  checkoutDeletePending: boolean
  checkoutUpdateItemPending: boolean
  checkoutValidatePending: boolean
  checkoutCancelPending: boolean

  isMinimumOrderModalVisible: boolean
  isDiscountModalVisible: boolean
  isCustomerInfoModalVisible: boolean
  isVisibleDeleteOrderModal: boolean
  isVisibleBoxPickupScheduleOrderModal: boolean

  checkoutStockErrorAdditionalData: CheckoutOutOfStockAdditionalData[] | null
  checkoutInvalidDataErrorAdditionalData:
    | CheckoutInvalidDataErrorAdditionalData[]
    | null

  checkoutPaymentAttempt: number

  checkoutStatusLastUpdatedAt: number

  checkoutTip: CheckoutTipState
}

type CheckoutParamsBase = {
  outletId: string
  checkoutId: string
  checkoutItemId: string
}

export type CheckoutAPIParams<K extends keyof CheckoutParamsBase> = {
  [key in K]: CheckoutParamsBase[K]
}

export enum CheckoutErrorContextType {
  AddCheckoutItem,
  CreateCheckout,
  UpdateCheckout,
  ChangeCheckoutItemQuantity,
}

export type CreateCheckoutRequestBody = {
  orderFormat: OrderFormat
  serviceTime: ServiceTime
  items: Array<CheckoutRequestItem>
  delivery?: CheckoutDelivery | null
  salesLocation?: {
    id?: string
    type: SalesLocationType
  }
  instructions?: string | null
  customerId?: string
  companyId: string | null
  metaData?: string | null
}

type AttendeeItem = {
  attendeeId: string | null
  items: CheckoutRequestItem[]
}

export type UpdateCheckoutRequestBody = Omit<
  CreateCheckoutRequestBody,
  'items'
> & {
  attendeeItems: AttendeeItem[]
}

export type CreateCheckoutResponse = {
  id: string
}

export type LocalStorageCartMeta = {
  outletId: string
  checkoutId: string
  isPreOrderCheckout?: boolean
}

export type LocalStorageCheckoutPaymentRedirect = {
  outletId: string
  checkoutId: string
}

export type CheckoutInvoicePaymentResponse = {
  paymentResult: PaymentProviderResult
  paymentId: string
}

export type YourInfoFormState = {
  firstName: string
  lastName: string
  phoneNumber: string
}

export type CheckoutStatusPageParams = {
  outletId?: string
  checkoutId?: string
}

export type CheckoutStatusPageQueryParams = CheckoutStatusPageParams & {
  /** Whether or not redirected from payment completion */
  redirect?: boolean
}

export type CheckoutResultPageQuery = CheckoutStatusPageQueryParams & {
  [k: string]: string
}

export type InvoicePaymentFormState = {
  invoiceReferenceNote: string
}

export type CheckoutUpdateError = {
  stockErrorAdditionalData?: CheckoutOutOfStockAdditionalData[]
  invalidDataErrorAdditionalData?: CheckoutInvalidDataErrorAdditionalData[]
}

type CheckoutOrderSummaryItem = {
  ticketItem: {
    status: TicketItemStatus
  } | null
}

type CheckoutOrderSummary = {
  id: string
  orderFormat: OrderFormat
  items: Array<CheckoutOrderSummaryItem | CheckOrderSummaryItem>
  metaData?: CheckoutMetaDataItem[]
  deliveryStatus?: CheckoutDeliveryStatus | null
  deliveryProvider?: CheckoutDeliveryProvider | null
}

type PreOrderOptions = {
  isHost: boolean
  preOrderStatus: PreOrderStatus
  preOrderCancelInfo?: PreOrderCancelInfo | null
  preOrderAttendeeStatus: AttendeePreOrderStatus
}

export type OrderSummaryStatusState = {
  checkout: CheckoutOrderSummary | null
  preparationTime?: string
  serviceTime?: ServiceTime
  checkoutStatus?: CheckoutStatus
  customersFetchTableOrders: boolean
  enablePartiallyPrepareOrders: boolean
  ticketStatus?: TicketStatus
  preOrderOptions?: PreOrderOptions
  isCheckOrder?: boolean
}

export type CheckoutPaymentSettings = {
  enableClickToPay: boolean
}

export type OrderInstructionsFormState = {
  orderNote: string
}

export type OrderSummaryTotalsType = {
  subtotalInclTax: Amount
  minimumOrderDifferenceAmount: Amount
  feeAmountInclTax: Amount
  discount: CheckoutDiscount | null
  subTotalDiscountInclTax: Amount
  orderFormat: OrderFormat
  totalAmount: Amount
}

export type CheckoutInvalidDataErrorAdditionalData = {
  errorCode: string
  productName: string
  productId: string
  variantId: string
}

export type CheckoutUnavailableProduct = IDNameItem
