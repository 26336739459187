import { APIGetRequest } from '@ancon/wildcat-types'

import endpoints from '../endpoints'
import Request from '../../client/Request'

type IntegrationsAPI = {
  get: {
    activeAdyenIntegration: APIGetRequest<
      {
        adyenIntegration: {
          id: string
          merchantId: string | null
          useBetaOnline: boolean
          useBetaTerminal: boolean
        }
      },
      { outletId: string }
    >
  }
}

const integrations: IntegrationsAPI = {
  get: {
    activeAdyenIntegration: new Request(
      endpoints.core.outlets.byId.integrations.activeAdyenIntegration.list,
    ).get,
  },
}

export default integrations
